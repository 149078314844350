import Header from 'main/header/Header';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './styles.css';
import ResponsiveAppBar from 'main/app-bar/AppBar';

const Dashboard: React.FC = () => {

  useEffect(() => {
    // Track the page view
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <>
      <Header />
      <ResponsiveAppBar/>
    </>
  );
};

export default Dashboard;
