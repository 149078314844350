import Typography from '@mui/material/Typography';
import { Link, Paper } from '@mui/material';
import ReactGA from "react-ga4";

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#F0F0F0', // Light gray background
  paddingY: '20px', // Increased padding for better spacing
  marginTop: '20px',
  width: 'calc(100%-20px)',
  boxShadow: '0px -5px 15px 1px rgba(0, 0, 0, 0.1)',
  paddingX: '20px',
};

export interface ArticleProps {
  header: string;
  text: string;
  link: string;
}

const Article = (props: ArticleProps) => {

  const handleClick = () => {
    ReactGA.event({
      category: 'Link',
      action: 'Click',
      label: props.header,
    });
  };

  return (
    <Paper
      sx={{
        ...style,
      }}
    >
      <Typography
        variant="body1"
        sx={{ paddingTop: '10px', borderBottom: '1px', width: '100%' }}
      >
        <span style={{ fontWeight: 'bold' }}>{props.header}</span> <br />
        {props.text} <br />
        <Link href={props.link} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
          Link
        </Link>
      </Typography>
    </Paper>
  );
};
export default Article;
