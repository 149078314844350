import { ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import getTheme from './getTheme';
import Dashboard from "./main/dashboard/Dashboard";
import PageNotFound from "./main/page-not-found";
import Content from "./main/dashboard/Content"; // Import Content Component
import Info from './main/dashboard/info/Info';  // Import Info Component
import News from './main/dashboard/news/News';  // Import News Component

export const App = () => {
    const [isMobile, setIsMobile] = useState(true);
    const theme = getTheme();

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 800); // Adjust the breakpoint as needed
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        // For debugging purposes
        Object.defineProperty(window, 'environment', {
            value: process.env.REACT_APP_ENVIRONMENT,
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Dashboard />
                <Routes>
                    <Route path="/" element={<Content isMobile={isMobile} />} />
                    <Route path="/info" element={<Info />} />
                    <Route path="/uudised" element={<News />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default App;
