import { Link, Paper, Typography } from '@mui/material';

const style = {
  paddingX: '20px',
  background: '#F0F0F0', // Light gray background
  paddingY: '20px', // Increased padding for better spacing
  marginTop: '20px',
  width: 'calc(100%-20px)',
  boxShadow: '0px -5px 15px 1px rgba(0, 0, 0, 0.1)',
};

const Info = () => {
  return (
    <Paper
      sx={{
        ...style,
      }}
    >
      <Typography>
        <span style={{ fontWeight: 'bold' }}>
          Mootorsõidukimaks rakendub järgmiste kategooriate sõidukitele:
        </span>
        <ul>
          <li>Sõiduautod - M1 ja M1G</li>
          <li>Kaubikud, furgoonid, pikapid - N1 ja N1G</li>
          <li> Mootorrattad - L3e, L4e, L5e, L6e ja L7e</li>
          <li> Maastikusõidukid - MS2</li>
          <li> Ratastraktorid - T1b, T3 ja T5</li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>Mootorsõidukimaksu eesmärk</span>{' '}
        on kehtestada Eestis sõidukitele suunatud keskkonnamaks. Sõidukite
        kasutamisega kaasneb oluline negatiivne välismõju (tootmissaaste,
        heitmed, ajakulu, ummikud, õnnetused jm). Seetõttu on maksustamise
        aluste väljatöötamisel seatud eesmärgiks kasutada sõidukite neid
        omadusi, mis väljendavad nende mõju keskkonnale – CO2 eriheide ja mass.
        <br />
        <br />
        Eesti autopark on CO2  heite järgi peaaegu Euroopa kõige saastavam, kuid
        samas ei ole kindlasti Eestis planeeritava mootorsõidukimaksu eesmärgiks
        vanema autopargi kasutusest väljasurumine, sest keskkonnale ja
        majandusele tervikuna kasulikum, kui iga toodetud sõiduk kasutatakse
        kuni oma kasuliku eluea lõpuni.
        <br />
        <br />
        Lisaks eelnevale on mootorsõidukimaksu eesmärk inimeste autoostuvalikute
        ergutamine keskkonda vähem koormavate sõidukite suunas ning autost
        loobumise otsuste toetamiseks.
        <br />
        <br />
        Oma iseloomult on mootorsõidukimaks omandi- mitte tarbimismaks.
        Aastamaksu maksavad kõik isikud, kes Liiklusregistri andmete kohaselt on
        mootorsõiduki omanikud või vastutavad kasutajad liisingu puhul. 
        <br />
        <br />
        Mootorsõidukimaks jõustub kava kohaselt 2025. aastast.
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Maks koosneb kahest osast:</span>
        <ul>
          <li>
            <span style={{ fontWeight: 'bold' }}>Mootorsõidukimaksu</span>{' '}
            tasuvad kõik isikud, kes on märgitud sõiduki omanikuks
            liiklusregistris. Maksu kuvatakse ning tasutakse Maksu- ja
            Tolliameti e-keskkonnas. {' '}
          </li>
          <li>
            <span style={{ fontWeight: 'bold' }}>Registreerimistasu</span>{' '}
            makstakse üks kord enne uue või kasutatud sõiduki Eesti
            liiklusregistrisse registreerimist. Tasu kuvamine ja kogumine toimub
            Transpordiameti e-keskkonnas.{' '}
          </li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>Mootorsõidukimaksu tasumine</span>
        <br />
        <br />
        Maksu- ja Tolliamet väljastab maksuteate 15. veebruariks.
        <br />
        <br />
        Maks tuleb aasta jooksul tasuda kahes osas: pool maksusummast 15.
        juuniks ja ülejäänud 15. detsembriks.
        <br />
        <br />
        Soovi korral võib kogu maksusumma ühe korraga tasuda juba enne 15.
        juunit. Kes soovivad maksusumma kuumaksupõhiseks muuta, saavad selleks
        teha oma pangas püsimakse korralduse maksuameti ettemaksukontole.
        <br />
        <br />
        Need, kes ostavad sõiduki aasta keskel, peavad maksu tasuma: <br />
        Vahemikus 1. jaanuar – 31. september ostetud ja liiklusregistrisse
        kantud sõiduki puhul sama aasta 15. detsembriks. <br />
        Vahemikus 1.oktoober – 31. detsember ostetud sõiduki puhul järgmise
        aasta 1. juuniks.
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Maksu arvestamine</span>
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>
          M1 kategooria sõidukite mootorsõidukimaks
        </span>{' '}
        koosneb baasosast ja CO2 näidust, raskemate sõidukite puhul ka massist.
        <br />
        <br />
        Eraisikute sõiduautode puhul vähendab maksu suurust sõiduki vanus, kui
        see on rohkem kui 5 aastat. Selle mõjul jõuab maksusumma 20-aastaste ja
        vanemate autode puhul baasosa ehk 50 euroni.
        <ul>
          <li>Baasosa 50 eurot </li>
          <li>
            {' '}
            CO2 osa – maks hakkab kehtima tasemest 118g CO2/km kohta.
            <ul>
              <li>Iga gramm vahemikus 118-150 = 3 € </li>
              <li>Iga gramm vahemikus 151-200 = 3,5 € </li>
              <li>Iga gramm üle 201 = 4 € </li>
            </ul>
          </li>
          <li>
            {' '}
            Massi osa – raskematele autodele rakendub massi komponent: 2000
            kilogrammist täismassi ületav iga kilogramm maksustatakse 0,40
            euroga kuni summani 400 eurot.
            <ul>
              <li>Hübriididel rakendub massi osa alates 2200 kilogrammist </li>
              <li>
                Elektriautodel rakendub massi osa alates 2400 kilogrammist.{' '}
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>
          N1-kategooria ehk kaubikute mootorsõidukimaks
        </span>{' '}
        koosneb baasosast ja CO2 näidust.
        <ul>
          <li>Baasosa 50 eurot </li>
          <li>Elektrikaubikutel baasosa 30 eurot.</li>
          <li>
            {' '}
            CO2 osa – iga CO2 heite gramm vahemikus 205–250g CO2/km kohta
            maksustatud 3 euroga.
            <ul>
              <li>
                Iga gramm vahemikus 251–300g CO2/km kohta on maksustatud 3,5
                euroga{' '}
              </li>
              <li>
                Iga gramm 301 ja üle selle CO2/km kohta on maksustatud 4 euroga.{' '}
              </li>
            </ul>
          </li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>Registreerimistasu</span> valemis
        on CO2 osakaal suurem, et soodustada vähemsaastavate sõidukite Eestisse
        toomist ja ostmist.
        <br />
        <br />
        Registreerimistasu sõiduautodele on kolmeosaline:
        <ul>
          <li>baasosa 150 eurot </li>
          <li>
            CO2 põhinev komponent 1-117 g/km = 5 €/g; 118-150 = 10 €/g; 151-200
            = 30€/g; 201+ = 50 €/g{' '}
          </li>
          <li>
            Täismassi komponent algab tavaautodel 2000 kg-st, pistikhübriididel
            2200 kg-st, elektriautodel 2400 kg-st ja on 2 €/kg. Massiosa piir
            2000 €, elektriautodel 2200 €.{' '}
          </li>
        </ul>
        CO2 on määratud WLTP meetodiga. NEDC meetodiga auto puhul korrutatakse
        CO2 näitaja 1,21-ga
        <ul>
          <li>
            CO2 näitaja puudumisel tuletatakse WLTP näidule lähedane näit
            alternatiivsete parameetrite alusel (võimsus, tühimass, vanus,
            kütuse liik){' '}
          </li>
          <li>Täiselektrilise sõiduki tasu = baasosa + massiosa </li>
          <li>
            Füüsilise isiku tasu vähendab vanusekordaja sarnaselt
            mootorsõidukimaksule.{' '}
          </li>
        </ul>
        Registreerimistasu kaubikutele on kaheosaline:
        <ul>
          <li>Baasosa 300 eurot</li>
          <li>
            CO2 põhinev komponent 1-204 g/km = 2 €/g, 205-250 g/km = 30 €/g,
            251-300 = 35€/g, 300+ = 40 €/g
          </li>
        </ul>
        CO2 on määratud WLTP meetodiga. NEDC meetodiga arvutatud CO2 näitajaga
        auto puhul korrutatakse CO2 näitaja 1,3-ga
        <ul>
          <li>
            Kui registris pole CO2 näitajat, tuletatakse WLTP näidule lähedane
            näit alternatiivsete parameetrite alusel (võimsus, tühimass, vanus){' '}
          </li>
          <li>
            Täiselektrilise kaubiku registreerimistasu = baasosa 200 eurot
          </li>
          <li>Kaubiku vanus registreerimistasu ei vähenda </li>
        </ul>
        <span style={{ fontWeight: 'bold' }}>Autokaravanid </span> <br />
        Autokaravanide CO2 heide on suhteliselt kõrge, seda peamiselt tulenevalt
        nende raskusest ja kerekujust. Autokaravanid kuuluvad muidu M1 ehk
        sõiduautode kategooriasse, kuid selleks et nende maksusummad liiga
        suureks ei läheks, maksustatakse nad N1 kategooria määrade alusel.
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Pistikhübriidid </span> <br />
        Pistikhübriidid on oma akude tõttu võrreldes samasuguste tavaliste
        sisepõlemismootoriga autodega raskemad, tavaliselt 100–200 kilogrammi
        võrra. <br />
        Pistikhübriidide kasutamisel on praktikas võimalik saavutada väiksem
        kasvuhoonegaaside emissioon võrreldes traditsiooniliste
        sisepõlemismootoriga sõidukitega. <br />
        Soodustamaks nende soetamist hakkab pistikhübriidi puhul massi komponent
        maksu suurust kasvatama alates 2200 kilogrammi kaaluvate sõidukite
        puhul. Iga seda piiri ületav kilogramm maksustatakse 0,40 euroga.
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Muuseumisõidukid</span> <br />
        Koostöös Kultuuriministeeriumiga töötame enne seaduse kehtestamist välja
        meetmed ametlikult tegutsevate muuseumide mootorsõidukimaksu koormuse
        leevendamiseks.
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>Romusõidukid</span> <br />
        Mootorsõidukimaksu väljatöötamine on ühiskonnas ja meie partnerite vahel
        algatanud viljaka diskussiooni ka n-ö romusõidukite teemal. Selle
        tulemusel on Kliimaministeeriumil valmimas meetmed romusõidukite arvu
        piiramiseks ja oluliseks vähendamiseks, mis eeldab ka vastavat
        õigusaktide muudatuste paketti.
      </Typography>
      <Typography variant="body2" sx={{ paddingTop: '20px' }} align="center">
        {'Allikas: Rahandusministeerium'}
        <Link
          href="https://www.fin.ee/uudised/registreerimistasu-maksmine-laieneb-kuid-maarad-vahenevad"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ padding: '5px' }}
        >
          link
        </Link>
      </Typography>
    </Paper>
  );
};

export default Info;
