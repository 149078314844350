import Article from './Article';

const News = () => {
    return (
        <>
            <Article
                header='Automaks võtab noortelt veelgi enam võimaluse soetada oma auto'
                text='Kuigi noored on kärmemad kliimapaanikaga kaasa minema, tähendab auto neilegi rohkemaid võimalusi liikuda ja maailma näha, kuid maksukoormuse kiire kasvu tõttu on nemad esimesed, kelle käed autoni ei ulatu.'
                link='https://uueduudised.ee/automaks-votab-noortelt-veelgi-enam-voimaluse-soetada-oma-auto/'
            />
            <Article
                header='Rein Karofeld: automaksu tulemusel suureneb isiklikku sõiduvahendit mitte omavate noorte hulk veelgi'
                text='Tänane Eesti automüügiturg ei peegelda vähemalt seni, et käes oleks mootorsõidukimaksu eelne periood – uute sõiduautode turg on võrreldes eelneva aastaga pea 14%-ses languses.'
                link='https://www.seb.ee/foorum/pressiuudised/rein-karofeld-automaksu-tulemusel-suureneb-isiklikku-soiduvahendit-mitte'
            />
            <Article
                header='Automaks ehk mootorsõidukimaks koosneb aastamaksust ja registreerimistasust'
                text='Riigikogu võttis 29. juulil vastu ning Vabariigi President kuulutas 9. augustil välja uuendatud mootorsõidukimaksu seaduse, mis jõustub 1. jaanuaril 2025.'
                link='https://rmp.geenius.ee/maksud/maksud-yldiselt/automaks-ehk-mootorsoidukimaks-koosneb-aastamaksust-ja-registreerimistasust/'
            />
            <Article
                header='Automaks sai rohelise tule: president kuulutas välja kaks seadust'
                text='President Alar Karis andis täna heakskiidu mootorsõidukimaksu seadusele ja tulumaksuseaduse muutmise seadusele.'
                link='https://www.postimees.ee/8074972/automaks-sai-rohelise-tule-president-kuulutas-valja-kaks-seadust'
            />
            <Article
                header='Automaks annab uute sõidukite müügis juba tunda, tulevikus ilmselt enamgi'
                text='Uute autode müük on Eestis tänavu languses: jaanuarist juulini müüsid autode maaletoojad ligi 14 protsenti vähem autosid, kui mullu samal ajal. Müügispetsialistid viitavad põhjustena majanduslikule ebakindlusele ja ka automaksule.'
                link='https://www.err.ee/1609418284/automaks-annab-uute-soidukite-muugis-juba-tunda-tulevikus-ilmselt-enamgi'
            />
            <Article
                header='President kuulutas mootorsõidukimaksu seaduse välja'
                text='President Alar Karis andis reedel heakskiidu mootorsõidukimaksu seadusele, mille ta varasemas versioonis oli jätnud välja kuulutamata.'
                link='https://www.err.ee/1609420837/president-kuulutas-mootorsoidukimaksu-seaduse-valja'
            />
            <Article
                header='President kuulutas välja automaksu ja tulumaksuseaduse muutmise'
                text='President Alar Karis andis täna heakskiidu mootorsõidukimaksu seadusele ja tulumaksuseaduse muutmise seadusele.'
                link='https://www.aripaev.ee/uudised/2024/08/09/president-kuulutas-valja-automaksu-ja-tulumaksuseaduse-muutmise'
            />
            <Article
                header='Rahanduskomisjon lükkas automaksu seaduse arutamise teadmata tulevikku'
                text='Rahanduskomisjon arutas erakorralisel istungil mootorsõidukimaksu seaduse ettevalmistamist teadmata kuupäeval uuesti riigikokku saatmiseks. Rahanduskomisjoni esimees ei välista, et maks võiks hakata kehtima juba järgmise aasta algusest.'
                link='https://www.err.ee/1609385882/rahanduskomisjon-lukkas-automaksu-seaduse-arutamise-teadmata-tulevikku'
            />
            <Article
                header='Lenno Uusküla: automaks on valesti üles ehitatud! Praegu ei lahenda see kumbagi püstitatud eesmärki'
                text='Automaksuga proovitakse Eestis korraga kehtestada varamaksu ja keskkonnamaksu. Nende eesmärkide koos saavutamine on aga peaaegu võimatu ja praegusel juhul ei ole kindel kummagi eesmärgi saavutamine.'
                link='https://epl.delfi.ee/artikkel/120304585/lenno-uuskula-automaks-on-valesti-ules-ehitatud-praegu-ei-lahenda-see-kumbagi-pustitatud-eesmarki'
            />
            <Article
                header='Isamaa saadik: Automaks tehakse soodustuste kaotamisega veel ebaausamaks'
                text='Riigikogu rahanduskomisjon otsustas tänasel esmaspäevasel erakorralisel istungil, et presidendi poolt välja kuulutamata jäetud automaksu eelnõu läheb parlamendis muutmisele.'
                link='https://lounaeestlane.ee/isamaa-saadik-automaks-tehakse-soodustuste-kaotamisega-veel-ebaausamaks/'
            />
            <Article
                header='President Karis jättis automaksu seaduse välja kuulutamata'
                text='President Alar Karis jättis välja kuulutamata mootorsõidukimaksu seaduse, leides, et see pole kooskõlas põhiseadusega, kuna rikub puuetega inimesi puudutavas osas võrdse kohtlemise põhimõtet.'
                link='https://www.err.ee/1609380782/president-karis-jattis-automaksu-seaduse-valja-kuulutamata'
            />
            <Article
                header='Automaks tuleb: riigikogu hääletas uue seaduse poolt'
                text='Riigikogu võttis kolmapäeval vastu valitsuse algatatud mootorsõidukimaksu seaduse, millega kehtestatakse järgmisest aastast kaheosaline mootorsõidukimaks.'
                link='https://majandus.postimees.ee/8039658/automaks-tuleb-riigikogu-haaletas-uue-seaduse-poolt'
            />
            <Article
                header='Automaks muutub jälle. Registreerimistasu laieneb kõigile'
                text='Kui varasema versiooni kohaselt pidi automaksu ühe osana registreerimistasu maksma ainult väljastpoolt Eestit ostetud sõiduki eest, siis nüüd on rahandusministeerium esitamas muudatust, mis laiendab registreerimistasu ka Eestist ostetud kasutatud sõidukitele.'
                link='https://arileht.delfi.ee/artikkel/120289540/automaks-muutub-jalle-registreerimistasu-laieneb-koigile'
            />
            <Article
                header='Võrklaev: mootorsõidukimaksu suurus lähtub sõiduki keskkonnamõjust'
                text='Eesti autopark on CO2 heite järgi Euroopas pea kõige saastavam ja autostumine kasvutrendis. Seetõttu on uue maksu disainil võetud prioriteediks autostumise pidurdamine, säästliku liikuvuse suurendamine ja inimeste nügimine keskkonnasõbralikumate sõidukite poole.'
                link='https://www.fin.ee/uudised/vorklaev-mootorsoidukimaksu-suurus-lahtub-soiduki-keskkonnamojust'
            />
            <Article
                header='Automaks muutub jälle. Registreerimistasu laieneb kõigile'
                text='Kui varasema versiooni kohaselt pidi automaksu ühe osana registreerimistasu maksma ainult väljastpoolt Eestit ostetud sõiduki eest, siis nüüd on rahandusministeerium esitamas muudatust, mis laiendab registreerimistasu ka Eestist ostetud kasutatud sõidukitele.'
                link='https://arileht.delfi.ee/artikkel/120289540/automaks-muutub-jalle-registreerimistasu-laieneb-koigile'
            />
            <Article
                header='Võrklaev: automaks lahendab Eestis ära mitu probleemi'
                text='Riigikogus tuleb kolmapäeval esimesele lugemisele mootorsõidukimaksu eelnõu – uus maks nügib meid tegema keskkonnasõbralikumaid valikuid ja aitab vähendada autostumist, on selle maksu vajadust põhjendanud rahandusminister Mart Võrklaev (Reformierakond)..'
                link='https://majandus.postimees.ee/7959959/vorklaev-automaks-lahendab-eestis-ara-mitu-probleemi'
            />
            <Article
                header='Kardetud automaks võib euronõuete tõttu kerkida oluliselt kõrgemaks'
                text='Automaks kujuneb seni arvatust tõenäoliselt rängemaks – tunnustatud juristide sõnul ei vasta selle praegune versioon, mis riigikogus pooleli, Euroopa Liidu õigusele, see aga tähendab, et kui praeguse eelnõu järgi ähvardavad rängad registreerimistasud väiksemat osa autoomanikest, siis nüüd võidakse kõik letti võtta.'
                link='https://pealinn.ee/2024/03/26/kardetud-automaks-voib-euronouete-tottu-kerkida-oluliselt-korgemaks/'
            />
            <Article
                header='Liivamägi: automaksu saab maksta ka osade kaupa'
                text='Rahandusministeerium analüüsib, kuidas langetada automaksueelnõus autode registreerimistasu summat, nii et see oleks vastuvõetav ka nendele autodele, mis Eesti registris juba on, ütles rahandusministeeriumi asekantsler Evelyn Liivamägi Vikerraadio saates "Reporteritund". Ühtlasi on plaanis võimaldada automaksu maksmist osade kaupa.'
                link='https://www.err.ee/1609312659/liivamagi-automaksu-saab-maksta-ka-osade-kaupa'
            />
            <Article
                header='Euroopa Komisjon hoiatab: Eesti automaks võib olla ELi õigustega vastuolus'
                text='Euroopa Komisjon on väljendanud muret Eesti plaanitava automaksuga seoses, leides, et see diskrimineerib imporditud autosid.'
                link='https://majandus.postimees.ee/7993742/euroopa-komisjon-hoiatab-eesti-automaks-voib-olla-eli-oigustega-vastuoluss'
            />
            <Article
                header='Automaksu uus plaan: isegi Eestis registreeritud autodelt peab omanikuvahetusel krõbedat lõivu maksma'
                text='Kavandatav automaks jaguneb kaheks: aastamaks ja registreerimistasu. Kui esialgu oli viimast plaanis kohaldada üksnes uute autode esmaregistreerimisele Eestis ja välismaalt toodud kasutatud autodele, siis nüüd on plaanis hakata maksustama kõiki omanikuvahetusi ja autode edasimüüke. Küll aga väheneks sellega maksumäär.'
                link='https://auto.geenius.ee/rubriik/uudis/automaksu-uus-plaan-registreerimistasu-hakatakse-koguma-eestis-ka-iga-omanikuvahetuse-puhul/'
            />
            <Article
                header='Automaksu traagika: muuseumiauto eest tuleb tasuda pea 1000 eurot aastas'
                text='LaitseRallyPark, mille kollektsiooni kuulub enam kui 100 uunikumi ja sportmasinat, esitas rahandusministeeriumile päringu, et teada saada, kui palju tuleb kollektsiooni kuuluvate autode eest edaspidi automaksu maksta.'
                link='https://auto.geenius.ee/rubriik/uudis/automaksu-traagika-muuseumiauto-eest-tuleb-tasuda-pea-1000-eurot-aastas/'
            />
            <Article
                header='ISAMAAS PETTUNUD | Autoomanikud: kas siis nii meie huvide eest Tallinna koalitsioonis seistaksegi?'
                text='Koalitsiooniläbirääkimiste ajal saatis Eesti Autoomanike Liidu (EAOL) juhatus Isamaa esimehele Urmas Reinsalule ja erakonna Tallinna piirkonna juhile Riina Solmanile kirja, kus pööras tähelepanu tõsiasjale, et alles hiljuti Tallinnas jõustunud kiirusepiirangud on ebamõistlikud ja peavad minema tagasipööramisele. Kas autojuhtide eest tõesti seisti? Kahtlane!'
                link='https://www.ohtuleht.ee/1105522/isamaas-pettunud-autoomanikud-kas-siis-nii-meie-huvide-eest-tallinna-koalitsioonis-seistaksegi'
            />
        </>
    );
};

export default News;
