import { Action, legacy_createStore as createStore, Reducer, Store } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import AppComponent from './App';

interface IState {
  turns: string[];
}

type TAction1 = 'ACTION_1';

interface IAction1 extends Action<TAction1> {
  payload: string;
}

type TAppAction = IAction1;
const reducer: Reducer<IState, TAppAction> = (state = { turns: [] }) => state;
const store: Store<IState, TAppAction> = createStore(reducer);

ReactGA.initialize('G-D8WJGL2EHX');

const render = (Component: any) => {
  const domNode = document.getElementById('root');
  const root = createRoot(domNode!!);
  root.render(
    <Provider store={store}>
      <div>
        <Component />
      </div>
    </Provider>
  );
};

render(AppComponent);
