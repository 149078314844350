import { createTheme } from '@mui/material/styles';

const createTestTheme = () => {
    return createTheme({
        typography: {
            button: {
                fontWeight: 600,
                fontSize: '0.875rem',
                lineHeight: '1rem',
                border: '1px solid rgba(0, 0, 0, 0.5)',
            },
        },
        palette: {
            primary: {
                light: 'rgba(98, 0, 238, 1)',
                main: 'rgba(0, 153, 218, 1)',
                dark: 'rgba(0, 117, 179, 1)',
                contrastText: 'rgba(255, 255, 255, 1)',
            },
            action: {
                hover: 'rgba(217,220,245,0.5)',
                selected: 'rgba(33, 33, 33, 1)',
            },
        }
    });
};

export const getTheme = () =>
    createTestTheme();

export default getTheme;
