import React from 'react';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

const headerStyle: SxProps<Theme> = {
  background: '#F0F0F0', // Light gray background
  color: '#333', // Dark gray text
  paddingY: '20px',
  width: '100%', // Set the width to 100%
};

const titleStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: '#0061F2', // Blue title color
};

const Header: React.FC = () => {
  return (
    <Paper sx={headerStyle}>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h1" sx={titleStyle} align="center">
            Automaksu kalkulaator
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Header;
