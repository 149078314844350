import { Theme, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';

const PageNotFound = () => (
  <Box
    sx={{
      margin: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: `translate(-50%, -50%)`,
      textAlign: 'center',
      width: '50%',
    }}
  >
    <Typography
      sx={centeredMessageEmojiStyles}
      variant="h1"
      component="p"
      gutterBottom
    >
      🤷‍♂️
    </Typography>
    <Typography component="h1" variant="h5" color="#0061F2" gutterBottom>
      Lehekülge ei leitud.
    </Typography>
  </Box>
);

const centeredMessageEmojiStyles: SxProps<Theme> = {
  fontSize: '4rem',
};

export default PageNotFound;
